import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ออกแบบและพัฒนาเว็ปไซต์",
      "style": {
        "position": "relative"
      }
    }}>{`ออกแบบและพัฒนาเว็ปไซต์`}<a parentName="h1" {...{
        "href": "#%E0%B8%AD%E0%B8%AD%E0%B8%81%E0%B9%81%E0%B8%9A%E0%B8%9A%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C",
        "aria-label": "ออกแบบและพัฒนาเว็ปไซต์ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
ไม่ใช่เพียงแค่มีเว็ปไซต์ แต่เราพัฒนาครบทุกความต้องการ ตั้งแต่วางแผนการตลาด ออกแบบที่ตรงกับสินค้าหรือธุรกิจของคุณ เชื่อมต่อกับระบบอื่นๆเพื่อขยายการทำงานร่วมกัน เหมาะสำหรับทุกองค์กรที่กำลังมองหาเว็ปไซต์ที่ช่วยเสริมภาพลักษณ์และขยายช่องทางธุรกิจขึ้นสู่ออนไลน์อย่างสมบูรณ์แบบ
    </LeadParagraph>
    <h4 {...{
      "id": "เว็ปไซต์ที่เรารับพัฒนา-ระบบอีคอมเมิร์ช-seree-ระบบ-cms-wordpressphpwagtailpython",
      "style": {
        "position": "relative"
      }
    }}>{`เว็ปไซต์ที่เรารับพัฒนา ระบบอีคอมเมิร์ช (Seree) ระบบ CMS (Wordpress(PHP),Wagtail(Python))`}<a parentName="h4" {...{
        "href": "#%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2-%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%84%E0%B8%AD%E0%B8%A1%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%8A-seree-%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A-cms-wordpressphpwagtailpython",
        "aria-label": "เว็ปไซต์ที่เรารับพัฒนา ระบบอีคอมเมิร์ช seree ระบบ cms wordpressphpwagtailpython permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "พัฒนาตามความต้องการของคุณ-โดยใช้-framework-เช่น-aspnet-core-6cdjangopythonlaravelphpphoenixelixirnodejsexpressnestjsfastify",
      "style": {
        "position": "relative"
      }
    }}>{`พัฒนาตามความต้องการของคุณ โดยใช้ Framework เช่น ASP.Net core 6(C#),Django(Python),Laravel(PHP),Phoenix(Elixir),NodeJS(Express,NestJS,Fastify)`}<a parentName="h4" {...{
        "href": "#%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%84%E0%B8%B8%E0%B8%93-%E0%B9%82%E0%B8%94%E0%B8%A2%E0%B9%83%E0%B8%8A%E0%B9%89-framework-%E0%B9%80%E0%B8%8A%E0%B9%88%E0%B8%99-aspnet-core-6cdjangopythonlaravelphpphoenixelixirnodejsexpressnestjsfastify",
        "aria-label": "พัฒนาตามความต้องการของคุณ โดยใช้ framework เช่น aspnet core 6cdjangopythonlaravelphpphoenixelixirnodejsexpressnestjsfastify permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Image size="M" src="/images/services/website-impl-process.png" alt="Anndream Software" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h2 {...{
      "id": "ตัวอย่างระบบงานที่เราพัฒนา",
      "style": {
        "position": "relative"
      }
    }}>{`ตัวอย่างระบบงานที่เราพัฒนา:`}<a parentName="h2" {...{
        "href": "#%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2",
        "aria-label": "ตัวอย่างระบบงานที่เราพัฒนา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h4 {...{
      "id": "--เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B9%82%E0%B8%94%E0%B8%A2%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%84%E0%B8%AD%E0%B8%A1%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%8A",
        "aria-label": "  เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์หน่วยงานเอกชน-ราชการ",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์หน่วยงานเอกชน ราชการ,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%AD%E0%B8%81%E0%B8%8A%E0%B8%99-%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%81%E0%B8%B2%E0%B8%A3",
        "aria-label": "  เว็ปไซต์หน่วยงานเอกชน ราชการ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ขายสินค้าการเกษตร",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายสินค้าการเกษตร,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%81%E0%B8%A9%E0%B8%95%E0%B8%A3",
        "aria-label": "  เว็ปไซต์ขายสินค้าการเกษตร permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ขายอสังหา",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายอสังหา,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AD%E0%B8%AA%E0%B8%B1%E0%B8%87%E0%B8%AB%E0%B8%B2",
        "aria-label": "  เว็ปไซต์ขายอสังหา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ส่วนตัว",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ส่วนตัว,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7",
        "aria-label": "  เว็ปไซต์ส่วนตัว permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์หางาน",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์หางาน,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AB%E0%B8%B2%E0%B8%87%E0%B8%B2%E0%B8%99",
        "aria-label": "  เว็ปไซต์หางาน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--ว็ปไซต์อีเลิร์นนิง",
      "style": {
        "position": "relative"
      }
    }}>{`- ว็ปไซต์อีเลิร์นนิง,`}<a parentName="h4" {...{
        "href": "#--%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AD%E0%B8%B5%E0%B9%80%E0%B8%A5%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%99%E0%B8%99%E0%B8%B4%E0%B8%87",
        "aria-label": "  ว็ปไซต์อีเลิร์นนิง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--และอื่นๆตามความต้องการ",
      "style": {
        "position": "relative"
      }
    }}>{`- และอื่นๆตามความต้องการ,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%AD%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B9%86%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3",
        "aria-label": "  และอื่นๆตามความต้องการ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h2 {...{
      "id": "ราคา",
      "style": {
        "position": "relative"
      }
    }}>{`ราคา`}<a parentName="h2" {...{
        "href": "#%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2",
        "aria-label": "ราคา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h4 {...{
      "id": "--เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช-ราคาเริ่มต้น-54000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช ราคาเริ่มต้น 54,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B9%82%E0%B8%94%E0%B8%A2%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A%E0%B8%AD%E0%B8%B5%E0%B8%84%E0%B8%AD%E0%B8%A1%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%8A-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-54000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์ขายสินค้าโดยใช้ระบบอีคอมเมิร์ช ราคาเริ่มต้น 54000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์หน่วยงานเอกชน-ราชการ-ราคาเริ่มต้น-35000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์หน่วยงานเอกชน ราชการ ราคาเริ่มต้น 35,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AB%E0%B8%99%E0%B9%88%E0%B8%A7%E0%B8%A2%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%AD%E0%B8%81%E0%B8%8A%E0%B8%99-%E0%B8%A3%E0%B8%B2%E0%B8%8A%E0%B8%81%E0%B8%B2%E0%B8%A3-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-35000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์หน่วยงานเอกชน ราชการ ราคาเริ่มต้น 35000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ขายสินค้าการเกษตร-ราคาเริ่มต้น-35000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายสินค้าการเกษตร ราคาเริ่มต้น 35,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AA%E0%B8%B4%E0%B8%99%E0%B8%84%E0%B9%89%E0%B8%B2%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%81%E0%B8%A9%E0%B8%95%E0%B8%A3-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-35000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์ขายสินค้าการเกษตร ราคาเริ่มต้น 35000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ขายอสังหา-ราคาเริ่มต้น-120000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ขายอสังหา ราคาเริ่มต้น 120,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%82%E0%B8%B2%E0%B8%A2%E0%B8%AD%E0%B8%AA%E0%B8%B1%E0%B8%87%E0%B8%AB%E0%B8%B2-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-120000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์ขายอสังหา ราคาเริ่มต้น 120000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์ส่วนตัว-ราคาเริ่มต้น-5000",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์ส่วนตัว ราคาเริ่มต้น 5,000,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%95%E0%B8%B1%E0%B8%A7-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-5000",
        "aria-label": "  เว็ปไซต์ส่วนตัว ราคาเริ่มต้น 5000 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์หางาน-ราคาเริ่มต้น-120000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์หางาน ราคาเริ่มต้น 120,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AB%E0%B8%B2%E0%B8%87%E0%B8%B2%E0%B8%99-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-120000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์หางาน ราคาเริ่มต้น 120000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--เว็ปไซต์อีเลิร์นนิง-ราคาเริ่มต้น-120000-บาท",
      "style": {
        "position": "relative"
      }
    }}>{`- เว็ปไซต์อีเลิร์นนิง ราคาเริ่มต้น 120,000 บาท,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C%E0%B8%AD%E0%B8%B5%E0%B9%80%E0%B8%A5%E0%B8%B4%E0%B8%A3%E0%B9%8C%E0%B8%99%E0%B8%99%E0%B8%B4%E0%B8%87-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%99-120000-%E0%B8%9A%E0%B8%B2%E0%B8%97",
        "aria-label": "  เว็ปไซต์อีเลิร์นนิง ราคาเริ่มต้น 120000 บาท permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "--และอื่นๆตามความต้องการ-ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ",
      "style": {
        "position": "relative"
      }
    }}>{`- และอื่นๆตามความต้องการ ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ,`}<a parentName="h4" {...{
        "href": "#--%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%AD%E0%B8%B7%E0%B9%88%E0%B8%99%E0%B9%86%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3-%E0%B8%A3%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%82%E0%B8%B6%E0%B9%89%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B9%80%E0%B8%A1%E0%B8%B4%E0%B8%99%E0%B8%95%E0%B8%B2%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%82%E0%B8%AD%E0%B8%87%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88",
        "aria-label": "  และอื่นๆตามความต้องการ ราคาขึ้นกับการประเมินตามความต้องการของธุรกิจ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Image size="M" src="/images/services/mycustomer.svg" alt="Anndream Software Customers" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} mdxType="Image" />
    <h4 {...{
      "id": "ง่ายครบ-จบในที่เดียว-ด้วยประสบการณ์กว่า-10-ปี-คุณมั่นใจ-ได้งานเร็ว-ไม่ทิ้งงาน-มีการดูแลต่อเนื่อง",
      "style": {
        "position": "relative"
      }
    }}>{`ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง`}<a parentName="h4" {...{
        "href": "#%E0%B8%87%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B8%84%E0%B8%A3%E0%B8%9A-%E0%B8%88%E0%B8%9A%E0%B9%83%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%94%E0%B8%B5%E0%B8%A2%E0%B8%A7-%E0%B8%94%E0%B9%89%E0%B8%A7%E0%B8%A2%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%AA%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%93%E0%B9%8C%E0%B8%81%E0%B8%A7%E0%B9%88%E0%B8%B2-10-%E0%B8%9B%E0%B8%B5-%E0%B8%84%E0%B8%B8%E0%B8%93%E0%B8%A1%E0%B8%B1%E0%B9%88%E0%B8%99%E0%B9%83%E0%B8%88-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99%E0%B9%80%E0%B8%A3%E0%B9%87%E0%B8%A7-%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%97%E0%B8%B4%E0%B9%89%E0%B8%87%E0%B8%87%E0%B8%B2%E0%B8%99-%E0%B8%A1%E0%B8%B5%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%94%E0%B8%B9%E0%B9%81%E0%B8%A5%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B9%80%E0%B8%99%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%87",
        "aria-label": "ง่ายครบ จบในที่เดียว ด้วยประสบการณ์กว่า 10 ปี คุณมั่นใจ ได้งานเร็ว ไม่ทิ้งงาน มีการดูแลต่อเนื่อง permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <h4 {...{
      "id": "สนใจพัฒนาเว็ปไซต์-สามารถปรึกษาได้ฟรี-ติดต่ออีเมล์-anndreamcomgmailcom",
      "style": {
        "position": "relative"
      }
    }}>{`สนใจพัฒนาเว็ปไซต์ สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ `}<a parentName="h4" {...{
        "href": "mailto:anndream.com@gmail.com"
      }}>{`anndream.com@gmail.com`}</a><a parentName="h4" {...{
        "href": "#%E0%B8%AA%E0%B8%99%E0%B9%83%E0%B8%88%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2%E0%B9%80%E0%B8%A7%E0%B9%87%E0%B8%9B%E0%B9%84%E0%B8%8B%E0%B8%95%E0%B9%8C-%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%9B%E0%B8%A3%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%9F%E0%B8%A3%E0%B8%B5-%E0%B8%95%E0%B8%B4%E0%B8%94%E0%B8%95%E0%B9%88%E0%B8%AD%E0%B8%AD%E0%B8%B5%E0%B9%80%E0%B8%A1%E0%B8%A5%E0%B9%8C-anndreamcomgmailcom",
        "aria-label": "สนใจพัฒนาเว็ปไซต์ สามารถปรึกษาได้ฟรี ติดต่ออีเมล์ anndreamcomgmailcom permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      